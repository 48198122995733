<template>
  <div>
    <h1><span class="title">您已成功加入</span></h1>
    <div class="sep"></div>
    <div class="wrap-content">
      <h6 style="text-align: center; color: #333; margin-bottom: 20px">
        您已成功加入 Huggies 好奇 LINE 官方帳號。
      </h6>
    </div>
    <div class="btnwrap fixedbottom">
      <a v-if="redirectUrl" :href="redirectUrl">
        <img src="./images/btn_next.png" :alt="redirectText">
      </a>
      <a v-else href="https://line.me/R/ti/p/@huggiestw">
        <img src="./images/return_btn.png" alt="結束"/>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return  {
      redirectUrl: null,
      redirectText: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "liffHuggies/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
  },
  created() {
    if (this.isLoading) {
      this.setLoading(false);
    }
  },
  mounted() {
    if (localStorage.getItem("huggies_redirect_url")) {
      this.redirectUrl = localStorage.getItem("huggies_redirect_url");
      localStorage.removeItem("huggies_redirect_url");
    }

    if (localStorage.getItem("huggies_redirect_text")) {
      this.redirectText = localStorage.getItem("huggies_redirect_text");
      localStorage.removeItem("huggies_redirect_text");
    }
  },
}
</script>

<style></style>
