<template>
  <div>
    <h1>
      <span class="title">簡訊驗證</span>
    </h1>
    <div class="sep"></div>
    <div class="wrap-content">
      <h6 style="text-align: center; color: #333; margin-bottom: 20px">
        請輸入發送至您手機的驗證碼
      </h6>
      <div class="mb-3" style="padding: 0">
        <input
          type="tel"
          class="form-control"
          id="code"
          name="code"
          placeholder="請輸入驗證碼"
          pattern="[0-9]{6}"
          v-model="verifyCode"
          :disabled="formLoading"
        />
      </div>
      <div id="countdown">
        <span id="sec">{{ seconds }}</span
        ><span> 秒後才可重新發送驗證碼</span>
        <h5
          id="resend"
          v-if="canResend"
          style="text-decoration: underline; cursor: pointer"
          @click="handleResend"
        >
          重新發送驗證碼
        </h5>
      </div>
    </div>
    <div class="btnwrap fixedbottom" id="final" @click="handleNext">
      <img src="./images/btn_next.png" alt="下一步" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      formLoading: false,
      verifyCode: "",
    };
  },
  created() {
    if (!this.timer) {
      this.setTimer();
    }
  },
  mounted() {
    if (this.isLoading) {
      this.setLoading(false);
    }
  },
  computed: {
    ...mapGetters({
      token: "liffHuggies/token",
      timer: "liffHuggies/timer",
      seconds: "liffHuggies/seconds",
      isLoading: "liffHuggies/isLoading",
    }),
    orgCode() {
      return this.$route.params.org_code;
    },
    canResend() {
      return !this.timer;
    },
  },

  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
      setTimer: "liffHuggies/setTimer",
      clearTimer: "liffHuggies/clearTimer",
    }),

    async handleNext() {
      if (!this.verifyCode) {
        alert("請輸入驗證碼");
        return;
      }

      this.formLoading = true;
      this.setLoading(true);

      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${this.orgCode}/liff/general/phone/verify`;

      try {
        await axios.post(
          url,
          {
            code: this.verifyCode,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.clearTimer();

        this.$router.push({
          name: "LiffHuggiesRegisterDone",
        }).then(() => {
          this.setLoading(false);
        });
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          alert("驗證碼錯誤");
        }
        this.setLoading(false);
        this.formLoading = false;
      }
    },

    async handleResend() {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${this.orgCode}/liff/general/phone/resend`;
      this.setLoading(true);

      try {
        await axios.post(url, null, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.setTimer();
      } catch (error) {
        alert(error.response.data.message);
      }
      this.setLoading(false);
    },
  },
};
</script>

<style></style>
