<template>
  <div class="huggies-register">
    <div class="header"></div>
    <div class="main-content">
      <div v-if="isLoading" class="load"></div>
      <router-view v-else></router-view>
    </div>
    <footer />
    <div class="bg-wrap"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      token: null,
      liffId: null,
    };
  },

  computed: {
    ...mapGetters({
      isLoading: "liffHuggies/isLoading",
      isPhoneVerified: "liffHuggies/isPhoneVerified",
      liffInfo: "liffHuggies/liffInfo",
    }),
  },

  async created() {
    this.setLoading(true);
    if (
      this.isPhoneVerified &&
      this.liffInfo.liff_force_friend &&
      this.$route.name !== "LiffHuggiesRegisterDone"
    ) {
      await this.$router.push({ name: "LiffHuggiesRegisterDone" });
    }
    this.setLoading(false);
  },

  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),

    async handleState() {
      const { path, query } = this.$route;

      const liffState = query["liff.state"];

      const newPath = path.substring(0, path.lastIndexOf("/"));

      if (liffState) {
        location.href = `${newPath}${liffState}`;
      }
    },

    liffFailed() {
      alert("初始化頁面失敗，請確認此網址是否正確");
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "Noto Sans TC", "Source Sans Pro", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  min-height: 100%;
  height: 100%;
}

.load {
  z-index: 500;
  position: fixed;
  top: 20%;
  left: 25%;
  height: 50px;
  width: 50%;
  background-image: url(./images/load.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.huggies-register {
  .header {
    background-image: url("./images/header_w.png");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 167px;
    z-index: 200;
  }
  .title {
    background: url("./images/dot.png") no-repeat left center,
      url("./images/dot.png") no-repeat right center;
    padding: 0 15px;
    background-size: 6px;
  }

  h1 {
    font-size: 1.25rem;
    margin: 15px auto;
    color: #e2211c;
    font-weight: 700;
  }
  .sep {
    background-image: url(./images/verify_line.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
  }
  .main-content {
    position: relative;
    margin: 200px auto;
    max-width: 500px;
    text-align: center;
    z-index: 100;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 397px;
    background: url("./images/footer_w.png") no-repeat bottom center;
    z-index: 2;
  }

  .bg-wrap {
    background: url("./images/bg_w.jpeg") no-repeat top center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .btnwrap {
    margin: 30px auto 100px;
    display: block;
    max-width: 300px;
    z-index: 3;
  }

  .btnwrap img {
    vertical-align: top;
    max-width: 100%;
    cursor: pointer;
  }

  @media only screen and (max-width: 550px) {
    .header {
      background-image: url("./images/header_m.png");
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 120px;
      z-index: 200;
    }
    footer {
      background: url("./images/footer_m.png") no-repeat bottom center;
    }
    .bg-wrap {
      background: url("./images/bg_m.jpeg") no-repeat top center;
    }
    .main-content {
      margin: 0 5%;
      margin-top: 150px;
    }
    .btnwrap img {
      vertical-align: top;
      max-width: 80%;
    }
  }
}
</style>
