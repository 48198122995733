<template>
  <div>
    <h1>
      <span class="title">申請 LINE 個人化服務</span>
    </h1>
    <p class="top-textbox">
      本服務提供至 Huggies 好奇台灣官方網站留存資料之會員申請使用。
    </p>
    <div class="scrollbarbox">
      <h2>貼心提醒</h2>
      <p class="sub">
        申請 LINE 官方帳號綁定服務時，Huggies 好奇將根據您提供的行動電話與 LINE
        帳號進行身份驗證，本查詢及驗證服務於 Huggies 好奇執行，不會經由 LINE
        紀錄資料。<br /><br />成功綁定個人化服務後，若更換手機或重新安裝 LINE
        APP，需以原本 LINE ID 登入，以利後續 LINE 個人化服務使用。<br />
      </p>
    </div>
    <div class="checkbox">
      <label class="check-wrap" for="privacycheck"
        >我已閱讀並同意
        <a
          href="https://www.huggies.com.tw/zh-tw/privacy-policy"
          target="_blank"
          >Kimberly-Clark 金百利克拉克公司所有隱私權保護政策及法律聲明 </a
        >，並接受相關條款與使用規範。
        <input
          name="privacy"
          id="privacycheck"
          v-model="privacyCheck"
          type="checkbox" /><span class="checkmark"></span
      ></label>
    </div>
    <div class="btnwrap fixedbottom" id="nextbtn" @click="handleNext">
      <img src="./images/btn_next.png" alt="下一步" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      privacyCheck: false,
    };
  },

  methods: {
    handleNext() {
      this.$router.push({ name: "LiffHuggiesRegister" });
    },
  },
};
</script>

<style>
.top-textbox {
  color: #333;
  font-size: 1rem;
  margin: 0 0 30px;
  text-align: left;
}

.scrollbarbox h2 {
  font-weight: 700;
  color: #cca636;
  text-align: center;
  font-size: 1rem;
  text-decoration: underline;
}

.scrollbarbox .sub {
  text-align: left;
  font-size: 0.9rem;
  color: #666;
}

.checkbox {
  border-top: 1px solid #999;
  margin: 15px 0 10px;
  padding: 15px 0 0;
  text-align: left;
  font-size: 0.9rem;
}

.check-wrap {
  padding-left: 30px;
  position: relative;
}

.check-wrap input {
  display: none;
}

.checkmark {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background: 0 0;
  border: 2px solid #333;
  border-radius: 0;
  -webkit-box-shadow: inset 2px 2px 3px 1px rgba(153, 153, 153, 0.8);
  box-shadow: inset 2px 2px 3px 1px rgba(153, 153, 153, 0.8);
}

.checkbox .check-wrap input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

a:link {
  color: #333;
  text-decoration: underline;
}

a:visited {
  color: #333;
  text-decoration: underline;
}

a:hover {
  color: #999;
  text-decoration: underline;
}

@media only screen and (max-width: 550px) {
  .scrollbarbox {
    height: 160px;
    overflow: auto;
  }

  .check-wrap {
    padding-left: 25px;
  }
}
</style>
