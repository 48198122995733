<template>
  <div>
    <h1 class="text-primary">折價券兌換</h1>
    <h2>優惠序號兌換</h2>
    <div class="p-4">
      <div v-if="redeemCode" class="h2 border no p-3">{{ redeemCode.code }}</div>
      <div class="font-weight-bold">*折扣碼將於 {{ timerDisplay }} 後失效</div>
      <a
        :href="link"
        class="button my-3"
      >
        <span class="h4 mb-0 font-weight-bold text-primary">立即<br>前往購買</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState('liffHuggies', {
      redeemCode: (state) => state.redeemCode,
      redeemEvent: (state) => state.redeemEvent,
    }),
    ...mapGetters({
      seconds: "liffHuggies/seconds",
      timer: "liffHuggies/timer",
    }),
    timerDisplay() {
      return (Math.round(this.seconds/60) - 1) + ":" + (this.seconds%60).toString().padStart(2, '0');
    },
  },
  watch: {
    seconds: {
      handler: function (newValue) {
        if (newValue === 0) {
          this.timerEnd();
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      privacyCheck: false,
      link: null,
      expired_label: "",
    };
  },
  methods: {
    ...mapActions({
      setLoading: 'liffHuggies/setLoading',
      fetchRedeemEvent: 'liffHuggies/fetchRedeemEvent',
      clearTimer: "liffHuggies/clearTimer",
    }),
    timerEnd() {
      this.setLoading(true);
      this.clearTimer();
      this.$router.push({
        name: "LiffHuggiesCouponECFinished",
        params: { org_code: this.$route.params.org_code },
      }).then(() => {
        this.setLoading(false);
      });
    },
    async init () {
      await this.fetchRedeemEvent();
      if (this.redeemCode?.config.coupon_type && this.redeemEvent?.config.links) {
        this.link = this.redeemEvent.config.links[this.redeemCode.config.coupon_type];
      }
    },
  }
};
</script>

<style>
  .no{
    border-width: 3px !important;
  }
</style>


