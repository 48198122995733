<template>
  <div>
    <h1 class="text-primary">折價券兌換</h1>
    <h2>實體通路選擇</h2>

    <div class="p-4">
      <p class="text-left">
        2022/04/01-2022/12/30，至指定合作實體通路，購買好奇裸感好動褲(M-XXL)，單箱／包即可立即折扺$50元
      </p>

      <div class="d-flex flex-nowrap justify-content-between">
        <b-form-select v-model="selectArea" size="lg" class="mr-3">
          <b-form-select-option :value="null">
            請選擇區域
          </b-form-select-option>
          <b-form-select-option
            v-for="parentBranch in parentBranches"
            v-bind:key="parentBranch.id"
            :value="parentBranch.id"
          >
            {{ parentBranch.name }}
          </b-form-select-option>
        </b-form-select>
        <b-form-select v-model="selectBranch" size="lg">
          <b-form-select-option v-if="childBranches.length === 0" :value="null" disabled>
            請先選擇區域
          </b-form-select-option>
          <b-form-select-option
            v-for="childBranch in childBranches"
            v-bind:key="childBranch.id"
            :value="childBranch.id"
          >
            {{ childBranch.name }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <button class="button my-4" v-b-modal.modal-confirm>
        <span class="h4 mb-0 font-weight-bold">確認送出</span>
        <span class="h5 mb-0 font-weight-bold text-primary"
          >（請臨櫃結帳時再按送出）</span
        >
      </button>

      <router-link
        :to="{name: 'LiffHuggiesCoupons'}"
        class="button my-4 d-inline-block w-auto"
      >
        <span class="h5 mb-0 font-weight-bold text-primary">返回上一頁</span>
      </router-link>

      <b-modal id="modal-confirm" size="sm" hide-footer hide-header centered>
        <div class="p-2">
          <p class="my-4 text-center">
            您確定要兌換折價券嗎？ <br />點選確認後30分鐘內臨櫃使用
          </p>
          <div class="d-flex">
            <button class="button mr-3" @click="$bvModal.hide('modal-confirm')">
              <span class="h4 mb-0 font-weight-bold text-primary">取消</span>
            </button>

            <a class="button" @click="submitRedeem">
              <span class="h4 mb-0 font-weight-bold text-primary"
                >確定使用</span
              >
            </a>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      privacyCheck: false,
      branches: [],
      childBranches: [],
      selectArea: null,
      selectBranch: null,
    };
  },
  mounted() {
    this.fetchBranches();
  },
  watch: {
    selectArea: function (newValue) {
      this.childBranches = this.branches.filter((branch) => {
        return branch.parent_id === newValue;
      });
      if (this.childBranches[0]) {
        this.selectBranch = this.childBranches[0].id;
      }
    },
  },
  computed: {
    ...mapState('liffHuggies', {
      orgCode: (state) => state.orgCode,
      redeemEvent: (state) => state.redeemEvent,
      eventCode: (state) => state.eventCode,
    }),
    ...mapGetters({
      // liffInfo: "liffHuggies/liffInfo",
      token: "liffHuggies/token",
      // me: "liffHuggies/me",
      // lineProfile: "liffHuggies/lineProfile",
      // isPhoneVerified: "liffHuggies/isPhoneVerified",
    }),
    parentBranches() {
      return this.branches.filter((branch) => {
        return branch.parent_id === "";
      });
    },
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
    async fetchBranches() {
      try {
        let url = process.env.VUE_APP_API_BASE_URL + "/" + this.orgCode + "/liff/redeem/branches";
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.branches = response.data.data;
      } catch (error) {
        console.error(error);
        alert('讀取資料錯誤');
      }
    },
    async submitRedeem() {
      if (!this.selectBranch) {
        alert('請選擇分店');
        return;
      }

      await this.setLoading(true);

      try {
        const url = process.env.VUE_APP_API_BASE_URL + `/${this.orgCode}/liff/redeem/redeem`;
        let response = await axios.post(url, {
          event_code: this.eventCode,
          branch: this.selectBranch,
          coupon_code: this.redeemEvent.config.coupon.store,
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.data?.status === 'success') {
          this.$router.push({
            name: 'LiffHuggiesCouponOfflineRedeem'
          }).then(() => {
            this.setLoading(false);
          });
        } else {
          if (response.data.message) {
            alert(response.data.message);
          }
          this.setLoading(false);
        }

      } catch (error) {
        this.setLoading(false);
        console.log(error);
        alert('索取失敗');
      }
    }
  }
};
</script>

