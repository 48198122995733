<template>
  <div>
    <h1 class="text-primary">折價券兌換</h1>
    <h2>優惠序號兌換</h2>

    <div class="p-4">
      <router-link
        :to="{ name: 'LiffHuggiesCouponECRedeem' }"
        class="button my-4"
      >
        <span class="h4 mb-0 font-weight-bold text-primary"
          >已兌換</span
        >
        <span class="h6 mb-0 font-weight-bold text-black"
          >電商通路折價券</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      privacyCheck: false,
    };
  },
};
</script>

