<template>
  <div>
    <h1 class="text-primary">折價券兌換</h1>
    <h2>電商通路選擇</h2>

    <div class="p-4">
      <p class="text-left">
        2022/04/01-2022/12/30，至指定合作實體通路，購買好奇裸感好動褲(M-XXL)，單箱／包即可立即折扺$50元
      </p>

      <a
        v-if="isAvailable('pchome')"
        @click.prevent="type = 'pchome'"
        v-b-modal.modal-confirm
        class="my-2 d-inline-block"
      >
        <div class="router w-50 m-auto">
          <img
            src="./images/router_pchome.jpg"
            class="img-fluid"
          />
        </div>
      </a>
      <a
        v-if="isAvailable('momo')"
        @click.prevent="type = 'momo'"
        v-b-modal.modal-confirm
        class="my-2 d-inline-block"
      >
        <div class="router w-50 m-auto">
          <img
            src="./images/router_momo.jpg"
            class="img-fluid"
          />
        </div>
      </a>
      <a
        v-if="isAvailable('shopee')"
        @click.prevent="type = 'shopee'"
        v-b-modal.modal-confirm
        class="my-2 d-inline-block"
      >
        <div class="router w-50 m-auto">
          <img
            src="./images/router_shopee.png"
            class="img-fluid"
          />
        </div>
      </a>

      <router-link
        :to="{name: 'LiffHuggiesCoupons'}"
        class="button my-4 d-inline-block w-auto"
      >
        <span class="h5 mb-0 font-weight-bold text-primary">返回上一頁</span>
      </router-link>
    </div>
    <b-modal id="modal-confirm" size="sm" hide-footer hide-header centered>
        <div class="p-2">
          <p class="my-4 text-center">
            您確定要兌換折價券嗎？ <br />點選確認後 30 分鐘內需使用完畢
          </p>
          <div class="d-flex">
            <button class="button mr-3" @click="$bvModal.hide('modal-confirm')">
              <span class="h4 mb-0 font-weight-bold text-primary">取消</span>
            </button>

            <a class="button" @click="submitRedeem()">
              <span class="h4 mb-0 font-weight-bold text-primary">確定使用</span>
            </a>
          </div>
        </div>
      </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      privacyCheck: false,
      type: null,
    };
  },
  computed: {
    ...mapState('liffHuggies', {
      token: (state) => state.token,
      orgCode: (state) => state.orgCode,
      redeemEvent: (state) => state.redeemEvent,
      eventCode: (state) => state.eventCode,
    }),
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
    isAvailable(type) {
      if (this.redeemEvent.config.available_time[type]?.start_at) {
        return (
          moment(this.redeemEvent.config.available_time[type].start_at).isSameOrBefore(moment()) &&
          moment(this.redeemEvent.config.available_time[type].end_at).isAfter(moment())
        );
      }
      return true;
    },
    async submitRedeem() {
      let type = this.type;
      await this.setLoading(true);
      try {
        const url = process.env.VUE_APP_API_BASE_URL + `/${this.orgCode}/liff/redeem/redeem`;

        await axios.post(url, {
          event_code: this.eventCode,
          branch: type,
          coupon_code: this.redeemEvent.config.coupon[type],
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        this.$router.push({
          name: 'LiffHuggiesCouponECRedeem'
        }).then(async () => {
          this.setLoading(false);
        });
      } catch (error) {
        console.log(error);
        alert('索取失敗');
        this.setLoading(false);
      }
    }
  }
};
</script>

<style>


.router {
  border: solid 3px #8b4d93;
    border-radius: 1.7rem;
    box-shadow: 0px 0px 0px 2px #fff inset,2px 2px 0 0 rgba(0, 0, 0, 0.2);
    padding: 4px;
  background: linear-gradient(
    135deg,
    rgba(237, 218, 180, 1) 0%,
    rgba(254, 240, 210, 1) 25%,
    rgba(195, 162, 107, 1) 50%,
    rgba(193, 159, 103, 1) 75%,
    rgba(215, 190, 141, 1) 100%
  );
}
.router img {
  border-radius: 1.3rem;
}
</style>
