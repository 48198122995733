<template>
  <div class="layout-coupon">
    <header></header>
    <div class="main-content">
      <div v-if="isLoading" class="load"></div>
      <router-view v-else></router-view>
    </div>
    <footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      token: null,
      liffId: null,
    };
  },

  computed: {
    ...mapGetters({
      isLoading: "liffHuggies/isLoading",
    }),
  },

  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
      setOrgCode: "liffHuggies/setOrgCode",
      initLiff: "liffHuggies/initLiff",
      fetchMe: "liffHuggies/fetchMe",
    }),

    async handleState() {
      const { path, query } = this.$route;

      const liffState = query["liff.state"];

      const newPath = path.substring(0, path.lastIndexOf("/"));

      if (liffState) {
        location.href = `${newPath}${liffState}`;
      }
    },

    liffFailed() {
      alert("初始化頁面失敗，請確認此網址是否正確");
    },
  },
};
</script>

<style lang="scss">
html {
  min-height: 100%;
}
body {
  font-family: "Noto Sans TC", "Source Sans Pro", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  background: url("./images/coupon_flower.png"),
    url("./images/coupon_flower_outline.png"),
    url("./images/coupon_flower_outline.png"), url("./images/coupon_flower.png"),
    url("./images/coupon_header.png"), url("./images/coupon_footer.png"),
    url("./images/bg_m.jpeg");
  background-size: 7%, 7%, 5%, 4%, 50%, 50%, auto;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat, repeat;
  background-position: -23px 25%, 102% 7%, 18% 75%, 81% 88%, top left,
    bottom right, top center;
  text-align: center;
  background-attachment: fixed;
}

@media only screen and (max-width: 550px) {
  body {
    background-size: 15%, 15%, 10%, 8%, 100%, 100%, auto;
    background-position: -23px 21%, 109% 7%, 18% 75%, 81% 90%, top left,
      bottom right, top center;
  }

  .layout-coupon {
    .main-content {
      padding: 30% 0;
    }
  }
}

.button {
  display: block;
  width: 100%;
  background: rgb(237, 218, 180);
  background: linear-gradient(
    135deg,
    rgba(237, 218, 180, 1) 0%,
    rgba(254, 240, 210, 1) 25%,
    rgba(195, 162, 107, 1) 50%,
    rgba(193, 159, 103, 1) 75%,
    rgba(215, 190, 141, 1) 100%
  );
  border: solid 1px #fff;
  border-radius: 1rem;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
  text-decoration: none !important;
}

.button span {
  display: block;
}

.modal-backdrop {
  opacity: 0.5;
}

.text-primary {
  color: #8b4d93 !important;
}

.load {
  z-index: 500;
  position: fixed;
  top: 20%;
  left: 25%;
  height: 50px;
  width: 50%;
  background-image: url(./images/load.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.layout-coupon {
  .main-content {
    max-width: 500px;
    padding: 15% 0;
    margin: auto;
  }

  h1 {
    font-size: 2rem;

    font-weight: bold;
  }

  h2 {
    font-weight: bold;
    color: #ae8e55;
    text-align: center;
    font-size: 1rem;
  }

  .custom-select {
    border-radius: 0;
    color: #fff;
    font-weight: bold;
    border: none;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.2);
    background: #c372a5
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23FFFFFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat;
  }
}
</style>
