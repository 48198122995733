<template>
  <div>
    <h1 class="text-primary">折價券兌換</h1>
    <h2>折價券通路選擇</h2>

    <div class="p-4">
      <a
        @click="goto('LiffHuggiesCouponOffline')"
        class="button my-4"
      >
        <span class="h4 mb-0 font-weight-bold text-black">實體通路</span>
        <span class="h4 mb-0 font-weight-bold text-primary">50元折價券</span>
      </a>

      <a
        @click="goto('LiffHuggiesCouponEC')"
        class="button my-4"
      >
        <span class="h4 mb-0 font-weight-bold text-black">電商通路</span>
        <span class="h4 mb-0 font-weight-bold text-primary">50元折價券</span>
      </a>


      <div class="h5 text-danger font-weight-bold">注意!!</div>
      <div class="text-danger small">實體通路與電商通路 <br />僅能擇一領取</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      privacyCheck: false,
    };
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
    goto(routeName) {
      this.setLoading(true);
      this.$router.push({
        name: routeName,
        params: { org_code: this.$route.params.org_code }
      }).then(() => {
        this.setLoading(false);
      })
    },
  }
};
</script>


