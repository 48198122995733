<template>
  <div>
    <h1 class="text-primary">折價券兌換</h1>
    <h2>{{ timerDisplay }}</h2>

    <div class="p-4">
      <img
        class="img-fluid"
        :src="couponImage"
      />
      <router-link
        :to="{
          name: 'LiffHuggiesCouponOfflineFinished',
        }"
        class="button my-3"
      >
        <span class="h4 mb-0 font-weight-bold text-primary">折價完畢</span>
      </router-link>

      <div class="small font-weight-bold">*門市人員刷修碼後點擊退出</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      privacyCheck: false,
    };
  },
  computed: {
    ...mapGetters({
      seconds: "liffHuggies/seconds",
      timer: "liffHuggies/timer",
      redeemEvent: "liffHuggies/redeemEvent",
    }),
    timerDisplay() {
      return (Math.round(this.seconds/60) - 1) + ":" + (this.seconds%60).toString().padStart(2, '0');
    },
    couponImage() {
      if (this.redeemEvent?.config.store_coupon_image) {
        return this.redeemEvent?.config.store_coupon_image;
      } else {
        return "https://storage.googleapis.com/waltily-dev/materials/4710036395396.gif";
      }
    }
  },
  watch: {
    seconds: {
      handler: function (newValue) {
        if (newValue === 0) {
          this.timerEnd();
        }
      }
    },
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
      clearTimer: "liffHuggies/clearTimer",
    }),
    timerEnd() {
      this.setLoading(true);
      this.clearTimer();
      this.$router.push({
        name: "LiffHuggiesCouponOfflineFinished",
        params: { org_code: this.$route.params.org_code },
      }).then(() => {
        this.setLoading(false);
      });
    },
  },
};
</script>

